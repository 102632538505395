
import axios from '../utils/shanRequest';
const baseShan = {
    ala: '/foo/alarm/member/getList',//查询设备使用人列表
    addUser: "/foo/alarm/member/addMember",//添加设备使用人
    upAla: '/foo/alarm/member/updateMember',//修改设备使用人
    deAla: '/foo/alarm/member/deleteMember',//删除设备使用人
    bindAla: '/foo/alarm/member/bindEquipment',//使用人绑定设备
    unBind: '/foo/alarm/member/unBindEquipment',//使用人解绑设备
    getLists: '/foo/alarm/history/getList',//告警历史记录
    getAppInfo: '/foo/alarm/user/getAppInfo',//查询应用信息
    sendPhoneCode: '/foo/login/sendPhoneCode',//获取验证码
    resetSecret: '/foo/alarm/user/resetSecret',//重置密钥
    getUserInfo: '/foo/alarm/user/getUserInfo',//查询用户信息
    testPushUrl: '/foo/alarm/user/testPushUrl',//测试接口
    updatePushType: '/foo/alarm/user/updatePushType',//修改账号订阅
    home: '/foo/alarm/user/home',//用户首页统计
    eqList: '/foo/alarm/user/getEquipmentList',//设备列表
    proList: '/foo/alarm/user/getProductList',//查询产品列表
    dict: '/foo/alarm/dict/getList',//字典
    getApiPushSleepDataConf: '/foo/alarm/user/getApiPushSleepDataConf',//查询睡眠数据实时推送配置
    testPushSleepUrl: '/foo/alarm/user/testPushSleepUrl',//测试睡眠数据接口
    updateApiPushSleepDataConf: '/foo/alarm/user/updateApiPushSleepDataConf',//修改睡眠数据实时推送配置
    updateApiPushStatus: '/foo/alarm/user/updateApiPushStatus',//修改睡眠数据实时推送状态
    getFace: '/foo/alarm/user/getInterfaceCallHisList',//API日志
}

//请求方法
export const apiShan = {
    getFace(params) {
        return axios.post(baseShan.getFace, params);
    },
    dict(params) {
        return axios.post(baseShan.dict, params);
    },
    //产品管理下拉框
    getala(params) {
        return axios.post(baseShan.ala, params);
    },
    getaddUser(params) {
        return axios.post(baseShan.addUser, params);
    },
    getupAla(params) {
        return axios.post(baseShan.upAla, params);
    },
    getdeAla(params) {
        return axios.post(baseShan.deAla, params);
    },
    getbindAla(params) {
        return axios.post(baseShan.bindAla, params);
    },
    getunBind(params) {
        return axios.post(baseShan.unBind, params);
    },
    //告警历史记录
    getLists(params) {
        return axios.post(baseShan.getLists, params)
    },
    //查询应用信息
    getAppInfo(params) {
        return axios.post(baseShan.getAppInfo, params)
    },
    //获取验证码
    sendPhoneCode(params) {
        return axios.post(baseShan.sendPhoneCode, params)
    },
    //重置密钥
    resetSecret(params) {
        return axios.post(baseShan.resetSecret, params)
    },
    //查询用户信息
    getUserInfo() {
        return axios.post(baseShan.getUserInfo)
    },
    //测试接口
    testPushUrl(params) {
        return axios.post(baseShan.testPushUrl, params)
    },
    //修改账号订阅
    updatePushType(params) {
        return axios.post(baseShan.updatePushType, params)
    },
    //用户首页统计
    home() {
        return axios.post(baseShan.home)
    },
    geteqList(params) {
        return axios.post(baseShan.eqList, params);
    },
    proList() {
        return axios.post(baseShan.proList);
    },
    //查询睡眠数据实时推送配置
    getApiPushSleepDataConf() {
        return axios.post(baseShan.getApiPushSleepDataConf);
    },
    //测试睡眠数据接口
    testPushSleepUrl(params) {
        return axios.post(baseShan.testPushSleepUrl, params);
    },
    //修改睡眠数据实时推送配置
    updateApiPushSleepDataConf(params) {
        return axios.post(baseShan.updateApiPushSleepDataConf, params);
    },
    //修改睡眠数据实时推送状态
    updateApiPushStatus(params) {
        return axios.post(baseShan.updateApiPushStatus, params);
    },
}

export default baseShan