<template>
  <div
    class="userservice"
    :style="{ height: clientHeight - 180 + 'px' }"
    style="overflow-y: scroll"
  >
    <el-form ref="form" :model="form" label-width="90px">
      <el-form-item label="apild">
        <el-input
          class="inputName"
          v-model="form.apiId"
          placeholder="请输入"
          :disabled="true"
        ></el-input>
      </el-form-item>
      <el-form-item label="apiKey">
        <el-input
          class="inputName"
          v-model="form.apiKey"
          placeholder="请输入"
          :disabled="true"
        ></el-input>
      </el-form-item>
      <el-form-item label="验证码">
        <el-input
          class="inputName"
          v-model="form.code"
          placeholder="请输入"
          style="width: 30%"
        ></el-input>
        <el-button type="primary" class="againBtn" @click="getCode"
          >获取验证码</el-button
        >
      </el-form-item>
      <el-form-item label="apiSecret">
        <el-input
          class="inputName"
          v-model="form.apiSecret"
          placeholder="请输入"
          :disabled="true"
        ></el-input>
        <el-button type="primary" class="againBtn" @click="agains"
          >重新生成</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { apiShan } from "../../../api1/shanConfig";
export default {
  data() {
    return {
      clientHeight: document.body.clientHeight,
      form: {
        apild: "",
        apiKey: "",
        apiSecret: "",
      },
    };
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  created() {
    this.getList();
  },
  watch: {
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    getList() {
      apiShan.getAppInfo().then((res) => {
        if (res.data.code == 200) {
          console.log("秘钥管理", res.data);
          this.form = res.data.result;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //获取验证码
    getCode() {
      let cunList = JSON.parse(window.localStorage.getItem("tokenShan"));
      let phone = cunList.phone;
      apiShan.sendPhoneCode({ phone: phone }).then((res) => {
        if (res.data.code == 200) {
          console.log("获取验证码", res.data);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //重新生成
    agains() {
      apiShan.resetSecret({ code: this.form.code }).then((res) => {
        if (res.data.code == 200) {
          console.log("重新生成", res.data);
          this.form.apiSecret = res.data.result;
        } else{
          this.$message.error(res.data.msg);
        }
      });
    },
  },
};
</script>

<style lang='less' scoped>
* {
  margin: 0;
  padding: 0;
}
.el-menu-vertical-demo {
  height: 100%;
}
/deep/.el-form {
  width: 60%;
  margin-left: 25px;
}
/deep/.inputName {
  padding-bottom: 40px !important;
  margin-left: 0 !important;
  width: 66%;
}
/deep/.el-form-item__label {
  text-align: left !important;
  font-size: 16px;
}
.againBtn {
  margin-left: 50px;
  padding: 11px 32px;
  font-size: 14px;
}
</style>